import { api } from "./backend";
import { listado } from "./listado";

export function crear() {
  return {
    id: 0,
    nombre: "",
    activa: 1,
  };
}

export async function listar(params) {
  return await listado("zona/listar", params, { activa: 1 });
}

export async function cargar(id) {
  const response = await api.get("zona/cargar", { id });
  return response.data;
}

export async function insertar(zona) {
  const response = await api.post("zona/insertar", { zona });
  return response.data;
}

export async function actualizar(zona) {
  const response = await api.post("zona/actualizar", { zona });
  return response.data;
}

export async function eliminar(id) {
  const response = await api.post("zona/eliminar", { id });
  return response.data;
}
