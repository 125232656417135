<template>
  <page fluid="lg" title="Crear zonas">
    <formulario :zona="zona" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiZonas from "@/services/zonas.js";

export default {
  components: { Formulario },
  data() {
    return {
      zona: apiZonas.crear(),
    };
  },
  methods: {
    async insertar() {
      let ok = await apiZonas.insertar(this.zona);
      if (ok) {
        this.$emit("userMessage", "Se ha creado la zona");
        this.$router.push({ path: "/administracion/zonas" });
      }
    },
  },
};
</script>
