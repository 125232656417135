<template>
  <b-form @submit.prevent="submit" autocomplete="off">
    <b-form-group label="Nombre">
      <b-form-input autofocus required type="text" v-model="zona.nombre" placeholder="Nombre de la zona"></b-form-input>
    </b-form-group>
    <b-form-group label="Estado">
      <b-form-checkbox v-model="zona.activa" switch value="1" unchecked-value="0">
        <b v-if="zona.activa == true">Activa</b>
        <b v-else>Inactiva</b>
      </b-form-checkbox>
    </b-form-group>
    <botonera-edicion url-cancelar="/administracion/zonas"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
export default {
  props: {
    zona: Object,
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
